import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ChatBotType } from '../../@types/chatBotType';
import URLConstants from '../../constants/urlConstants';


type ChatbotState = {
    isLoading: boolean;
    error: boolean;
    chatbot: ChatBotType;
}

const initialState: ChatbotState = {
    isLoading: false,
    error: false,
    chatbot: {} as ChatBotType
}

const slice = createSlice({
    name: 'chatbot',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // CREATE CHATBOT
        createChatBotSuccess(state, action) {
            state.isLoading = false;
            state.chatbot = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function createNewChatBot(whatsAppBusinessId:string, configurationName:string ,configuration :any) {
      
    return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.post(URLConstants.CREATE_CHATBOT_BASE_URL, {whatsAppBusinessId,configurationName,configuration});
                dispatch(slice.actions.createChatBotSuccess(response.data.data));
            } catch (error:any) {
                dispatch(slice.actions.hasError(error));
                return {message : error?.message, hasError : true} 
            }
        };
}

export function enableOrDisableChatbot(values:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response =  await axios.patch(URLConstants.ENABLE_DISABLE_CHATBOT_BASE_URL,values);
            dispatch(slice.actions.createChatBotSuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}

export function  chatbotDelete(configurationId:  string) {
    return async () => {
        try {
            const response = await axios.delete(`${URLConstants.CREATE_CHATBOT_BASE_URL}/${configurationId}`);
            dispatch(slice.actions.createChatBotSuccess(response.data.data));
        } catch (error: any) {
            dispatch(slice.actions.hasError(error));
            return { message: error?.message, hasError: true };
        }
    }
  }