import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
import { QuickReply } from '../../@types/quickReply';
import URLConstants from '../../constants/urlConstants';
import { AssignFeature } from '../../@types/assignFeature';


type AssignFeatureState = {
    isLoading: boolean;
    error: boolean;
    assignFeature: AssignFeature[];
}

const initialState: AssignFeatureState = {
    isLoading: false,
    error: false,
    assignFeature: []

}

const slice = createSlice({
    name: 'assignFeature',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ASSIGN FEATURE
        getAssignFeatureSuccess(state, action) {
            state.isLoading = false;
            state.assignFeature = action.payload;
        },
        getAssignFeatureByIdSuccess(state, action) {
            state.isLoading = false;
            state.assignFeature = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getAccounts(accountId:string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.AGENT_ASSIGN_FEATURE_BY_ID_URL+`/${accountId}`);
            dispatch(slice.actions.getAssignFeatureByIdSuccess(response.data.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}



export function enableOrDisableAgentAssignOrNotification(values: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try{
            const response = await axios.put(URLConstants.AGENT_ASSIGN_FEATURE_URL, values);
            dispatch(slice.actions.getAssignFeatureSuccess(response.data.data));
        }catch (error: any) {
            dispatch(slice.actions.hasError(error))
            return {message : error?.message, hasError : true}
        }
    }
}




