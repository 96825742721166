import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { DownloadAnalyticsType } from '../../@types/downloadAnalyticsType';
import URLConstants from '../../constants/urlConstants';
import { DownloadCenterType } from '../../@types/downloadCenterType';


type DownloadCenterState = {
    isLoading: boolean;
    error: boolean;
    activeMainTab : string,
    downloadCenter: DownloadCenterType[];
}

const initialState: DownloadCenterState = {
    isLoading: false,
    error: false,
    activeMainTab : 'home',
    downloadCenter: []
}

const slice = createSlice({
    name: 'downloadCenter',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setActiveMainTabSuccess(state,action){
            state.activeMainTab = action.payload
        },

           // GET LIST OF REPORTS IN DOWNLOAD CENTER
           getReportsInDownloadCenterSuccess(state, action) {
            state.isLoading = false;
            state.downloadCenter = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function setActiveMainTab(activeUser:string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setActiveMainTabSuccess(activeUser));
        } catch (error :any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };

}
export function getReportsListInDownoadCenter(page: number) {
    var params = {
        "page": page,
        "records": 20,
    }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.GET_DOWNLOAD_CENTER_REPORTS_BASE_URL, { params });
            dispatch(slice.actions.getReportsInDownloadCenterSuccess(response.data.data));
        } catch (error: any) {
            dispatch(slice.actions.hasError(error));
            return { message: error?.message, hasError: true }
        }
    };
}

