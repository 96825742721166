import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ApiKeyType } from '../../@types/apiKeyType';
import URLConstants from '../../constants/urlConstants';


type GetTemplatesState = {
    isLoading: boolean;
    error: boolean;
    apiKey: ApiKeyType[];
    newApiKey: ApiKeyType[];
}

const initialState: GetTemplatesState = {
    isLoading: false,
    error: false,
    apiKey: [],
    newApiKey: []
}

const slice = createSlice({
    name: 'apiKey',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ALL API KEYS
        getApiKeySuccess(state, action) {
            state.isLoading = false;
            state.apiKey = action.payload;
        },

        // CREATE API KEYS
        createApiKeySuccess(state, action) {
            state.isLoading = false;
            state.newApiKey = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getAllApiKeys() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.API_KEY_BASE_URL);
            dispatch(slice.actions.getApiKeySuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function createApiKey() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.API_KEY_BASE_URL);
            dispatch(slice.actions.createApiKeySuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };

}

export function blockApiKey(apiKey :string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(URLConstants.BLOCK_API_KEY_BASE_URL+apiKey,{"remarks": "remarks"});
            dispatch(slice.actions.getApiKeySuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };

}