import React, { Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

//import routes
import { authProtectedRoutes, publicRoutes } from "./routes";

//import layouts
import NonAuthLayout from "../layouts/NonAuth";
import AuthLayout from "../layouts/AuthLayout";
import useAuth from "../pages/Auth/useAuth";
import Login from "../pages/Auth/Login";
import LoadingScreen from "../pages/LoadingPage";

type AppRouteFunction = {
  component: any;
  layout: any;
  isAuthProtected: boolean;
  path: string;
  key: number;
};
// handle auth and authorization
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}: AppRouteFunction) => {
  const { isAuthenticated, isLoading } = useAuth();
  const history = useHistory();

  const { pathname } = useLocation();

  const isDashboard = pathname.includes("/dashboard");

  return (
    <Route
      {...rest}
      render={(props) => {

        // if(isLoading){
        //     return<LoadingScreen/>;
        // }
        if (!isAuthenticated && props.location.pathname !== "/register" && props.location.pathname !== "/forget-password" && props.location.pathname !== "/reset-password") {
          localStorage.removeItem("authUser");
          //localStorage.removeItem("accessToken");
          return <Login />;
          // return (
          //     <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          // );
        }
        // authorised so return component
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

/**
 * Main Route component
 */
const Routes = (props: any) => {
    const { isAuthenticated, isLoading } = useAuth();

  return (
    // rendering the router with layout
    <BrowserRouter>
      <React.Fragment>
        <Suspense
          fallback={
            <LoadingScreen
                sx={{
                    ...( {
                        top: 0,
                        left: 0,
                        width: 1,
                        zIndex: 9999,
                        position: "fixed",
                    }),
                }}
            />
        }>
          <Switch>
            {/* public routes */}
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {/* private/auth protected routes */}
            {!isLoading&&authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={AuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}

          </Switch>
        </Suspense>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Routes;
