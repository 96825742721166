import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ChatBotListType } from '../../@types/chatBotType';
import URLConstants from '../../constants/urlConstants';



type ChatbotListState = {
    isLoading: boolean;
    error: boolean;
    chatbotList: ChatBotListType[];
}

const initialState: ChatbotListState = {
    isLoading: false,
    error: false,
    chatbotList: []
}

const slice = createSlice({
    name: 'chatbotList',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ALL CHATBOTS
        getChatbotListSuccess(state, action) {
            state.isLoading = false;
            state.chatbotList = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getChatbotList(whatsAppBusinessId:string) {

        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(`${URLConstants.GET_CHATBOT_LIST_BASE_URL}/${whatsAppBusinessId}`);
                dispatch(slice.actions.getChatbotListSuccess(response.data.data));
            } catch (error) {
                dispatch(slice.actions.hasError(error));
            }
        };
}

