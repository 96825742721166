import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { WabaTemplate } from '../../@types/getTemplatesType';
import URLConstants from '../../constants/urlConstants';


type CreateTemplateState = {
    isLoading: boolean;
    error: boolean;
    createTemplate: WabaTemplate;
}

const initialState: CreateTemplateState = {
    isLoading: false,
    error: false,
    createTemplate: { } as WabaTemplate
}

const slice = createSlice({
    name: 'createTemplate',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // CREATE TEMPLATE
        createTemplateSuccess(state, action) {
            state.isLoading = false;
            state.createTemplate = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function createNewTemplate(
    whatsAppBusinessId: string, 
    name: string, 
    category: string,  
    components: any,
    language: string, ) {
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.post(URLConstants.CREATE_TEMPLATE_BASE_URL,
                    {whatsAppBusinessId,name,category,language,components}
                   
                );
                dispatch(slice.actions.createTemplateSuccess(response.data.data));
            } catch (error :any) {
                dispatch(slice.actions.hasError(error));
                return {message : error?.message, hasError : true} 
            }
        };

   
}