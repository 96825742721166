import { createSlice } from "@reduxjs/toolkit";
import { CampaignRcsListType } from "../../@types/campaignListType";
import { SentRcsCampaignType } from "../../@types/sentRcsCampaignType";
import URLConstants from "../../constants/urlConstants";
import axios from "../../utils/axios";
import { dispatch } from "../store";


type SendRcsMessageState = {
    isLoading: boolean;
    error: boolean;
    sendRcsCampaign: SentRcsCampaignType;
    campaignRcsList: CampaignRcsListType[];
}

const initialState: SendRcsMessageState = {
    isLoading: false,
    error: false,
    sendRcsCampaign: { } as SentRcsCampaignType,
    campaignRcsList: []
}

const slice = createSlice({
    name: 'sendCampaign',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // SEND CAMPAIGN
        sendRcsCampaignSuccess(state, action) {
            state.isLoading = false;
            state.sendRcsCampaign = action.payload;
        },

        getRcsCampaignListSuccess(state, action) {
            state.isLoading = false;
            state.campaignRcsList = action.payload;
        }
    }
});

export default slice.reducer

export function sendRcsNewCampaign(values: any) {
    let messages = []
    values.toContacts.map(contacts =>
            messages.push({
                "to": contacts,
                "rcsAccountId": values.rcsAccountId,
                "message": {
                    "text": values.message,
                    "fileUrl": values.s3MediaUrl
                }
            }) 
        )

        return async () => {
            dispatch(slice.actions.startLoading())
            try {
                const response = await axios.post(URLConstants.SEND_RCS_CAMPAIGN_MESSAGE_BASE_URL,{
                    "rcsAccountId": values.rcsAccountId,
                    "campaignName": values.campaignName,
                    "scheduleTime": values.scheduleTime,
                    "messages": messages
                })
            } catch (error: any) {
                dispatch(slice.actions.hasError(error));
                return {message: error?.message, hasError: true}
            }
        }
}


export function getRcsCampaignList(page: number, records: number) {
    var params = {
        "page": page,
        "records": records
    }

    return async () => {
        dispatch(slice.actions.startLoading());
        try{
            const response = await axios.get(URLConstants.RCS_CAMPAIGN_LIST_ALL, {params})
            dispatch(slice.actions.getRcsCampaignListSuccess(response.data.data))
        }catch(error: any){
            dispatch(slice.actions.hasError(error))
        }
    }
}