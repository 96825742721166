import React, { useState } from 'react';
import { RootState, dispatch, useSelector } from '../redux-persist/store';
import { Nav, NavItem, NavLink, UncontrolledTooltip, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import classnames from "classnames";
import { setActiveMainTab } from '../redux-persist/slices/downloadCenter';

//i18n

function LeftMenu(props:any) {

    const  {getWBAccount} = useSelector(
        (state: RootState) => state.getWBAccount
    );

    const { downloadCenter,activeMainTab } = useSelector(
        (state: RootState) => state.downloadCenter
    );
  

    const toggleTab = (tab: any) => {
        if (activeMainTab !== tab) 
        dispatch(setActiveMainTab(tab));
    }

    props.toggleTab(activeMainTab)

    return (
       <>
          <div className="flex-lg-column my-auto">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        <NavItem id="home">
                            <NavLink id="pills-user-tab" className={classnames({ active: activeMainTab === 'home' })} onClick={() => { toggleTab('home'); }}>
                                <i className="ri-home-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="home" placement="top">
                            Home
                        </UncontrolledTooltip>

                     
                        
                        <NavItem id="Chats">
                            <NavLink id="pills-chat-tab" className={classnames({ active: activeMainTab === 'chat' })} onClick={() => { toggleTab('chat'); }}>
                                <i className="ri-message-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Chats" placement="top">
                            Chats
                        </UncontrolledTooltip>
                        <NavItem id="Contacts">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: activeMainTab === 'contacts' })} onClick={() => { toggleTab('contacts'); }}>
                                <i className="ri-group-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Contacts" placement="top">
                            Contacts
                        </UncontrolledTooltip>
                        <NavItem id="broadcast">
                            <NavLink id="pills-broadcast-tab" className={classnames({ active: activeMainTab === 'broadcast' })} onClick={() => { toggleTab('broadcast'); }}>
                                <i className="ri-broadcast-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="broadcast" placement="top">
                        Broadcast
                        </UncontrolledTooltip>
                        <NavItem id="Settings">
                            <NavLink id="pills-setting-tab" className={classnames({ active: activeMainTab === 'settings' })} onClick={() => { toggleTab('settings'); }}>
                                <i className="ri-settings-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Settings" placement="top">
                            Settings
                        </UncontrolledTooltip>
                        <NavItem id="Reports">
                            <NavLink id="pills-report-tab" className={classnames({ active: activeMainTab === 'reports' })} onClick={() => { toggleTab('reports'); }}>
                              <i className="ri-file-chart-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Reports" placement="top">
                            Reports
                        </UncontrolledTooltip>
                      {getWBAccount[0] && getWBAccount[0].advancedChatbotEnabled && getWBAccount[0].accountId != "bbdb5e18-c564-4a0b-96fd-8c9f1f112562" &&  <>
                        <NavItem id="Chatbot">
                            <NavLink id="pills-chatbot-tab" className={classnames({ active: activeMainTab === 'chatbot' })} onClick={() => { toggleTab('chatbot'); }}>
                              <i className="ri-chat-settings-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Chatbot" placement="top">
                            Chatbot builder
                        </UncontrolledTooltip>
                        </> }
                    </Nav>
                </div>
       </>
    );
}

export default LeftMenu;