import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { GroupOfContact } from '../../@types/groupOfContact';
import URLConstants from '../../constants/urlConstants';


type CreateUserState = {
    isLoading: boolean;
    error: boolean;
    groupOfContact: GroupOfContact[];
}

const initialState: CreateUserState = {
    isLoading: false,
    error: false,
    groupOfContact: [{
        groupId: '',
        groupName: '',
        accountId: '',
        createTime: new Date(),
        updateTime: new Date(),
    }]
}

const slice = createSlice({
    name: 'groupOfContact',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // CREATE TEAM
        groupSuccess(state, action) {
            state.isLoading = false;
            state.groupOfContact = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getAllGroup() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.ALL_CONTACT_GROUP_BASE_URL,);
            dispatch(slice.actions.groupSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

