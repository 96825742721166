import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import {ConfigureWebhookType} from '../../@types/configureWebhookType';
import URLConstants from '../../constants/urlConstants';

type ConfigureWebhookState = {
    isLoading: boolean;
    error: boolean;
    configWebhook: ConfigureWebhookType;
}

const initialState: ConfigureWebhookState = {
    isLoading: false,
    error: false,
    configWebhook: {} as ConfigureWebhookType
}

const slice = createSlice({
    name: 'configWebhook',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // CONFIGURE WEBHOOK
        configWebhookSuccess(state, action) {
            state.isLoading = false;
            state.configWebhook = action.payload;
        },
    }
});
// Reducer
export default slice.reducer;
export function configWebhookAccount(whatsAppBusinessId:string,webhookCallbackURL:string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.CONFIGURE_WEBHOOK_BASE_URL,{whatsAppBusinessId,webhookCallbackURL});
            dispatch(slice.actions.configWebhookSuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}