export default class URLConstants {
  //BASE URLs
  static GAUS_BASE_URL: string =
    process.env.GAUS_BASE_URL || "https://api.telinfy.net/gaus";

  static GARCS_BASE_URL: string =
    process.env.GARCS_BASE_URL ||
    "https://v8tp59esih.execute-api.ap-south-1.amazonaws.com/dev/grcs";

  static GSNS_BASE_URL: string =
    process.env.GSNS_BASE_URL || "https://api.telinfy.net/gsns";

  static GABS_BASE_URL: string =
    process.env.GABS_BASE_URL || "https://api.telinfy.net/gabs";

  static GARP_BASE_URL: string =
    process.env.GARP_BASE_URL || "https://api.telinfy.net/garp";

  static GAAS_BASE_URL: string =
    process.env.GAAS_BASE_URL || "https://api.telinfy.net/gaas";

  static GCNS_BASE_URL: string =
    process.env.GCNS_BASE_URL || "https://api.telinfy.net/gcns";

  static GAMS_BASE_URL: string =
    process.env.GAMS_BASE_URL || "https://api.telinfy.net/gams";

  static G_360_BASE_URL: string =
    process.env.G_360_BASE_URL || "https://api.telinfy.net/g360";

  static GACA_BASE_URL: string =
    process.env.GCNS_BASE_URL || "https://api.telinfy.net/gaca";

  static CAMPAIGN_CONTACT_URL: string =
    process.env.CAMPAIGN_CONTACT_URL ||
    "https://s3.ap-south-1.amazonaws.com/telinfy.greenadsglobal.com/templates/whatsapp/general/campaign-contacts.xlsx";

  static LOGIN_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/login";
  static SIGNUP_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/signup";
  static USER_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/users/me";
  static CREATE_USER_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/users";
  static ALL_USER_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/accounts/me/users";
  static ADD_CONTACT_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contacts";
  static DELETE_CONTACT_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact/delete";
  static ALL_CONTACTS_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contacts";
  static CREATE_WB_ACCOUNT_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp-business/accounts";
  static UPDATE_WB_ACCOUNT_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp-business/";
  static TEAMS_OF_ACCOUNT_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/account/me/teams";
  static CREATE_TEAM_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/account/teams";
  static USER_OF_TEAM_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/team/:teamId/users";
  static GET_TEMPLATES_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp/templates";
  static GET_WB_ACCOUNT_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp-business/accounts";
  static SEARCH_CONTACTS_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact/search/";
  static ALL_CAMPAIGN_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp/all/campaign";
  static ACCOUNT_STATS_BASE_URL: string =
    URLConstants.GAAS_BASE_URL + "/accounts/me/stats";
  static VALIDATE_CONTACT_BASE_URL: string =
    URLConstants.G_360_BASE_URL + "/whatsapp/contact/validate";
  static EDIT_CONTACT_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact";
  static EDIT_PROFILE_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/user/me";
  static CONVERSATION_UNBLOCK_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation/customer-unblock";
  static CREATE_GROUP_IN_CONTACT_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact-groups";
  static ALL_CONTACT_GROUP_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact-groups";
  static CONTACT_GROUP_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/user/me/contact-groups";
  static ADD_CONTACTFILE_T0_GROUP_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact-groups/contacts";
  static DELETE_GROUP_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact-groups/group/delete";
  static DELETE_CONTACT_IN_GROUP_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact-groups";
  static BLOCK_CONTACT_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/contact";
  static GET_BLOCK_CONTACT_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/contacts/blocked-contacts";
  static BLOCK_CUSTOMER_BY_AGENT_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation";
  static DIRECTLY_BLOCK_CONTACT_BASE_URL: string =
    URLConstants.GARP_BASE_URL + "/users/me/create-contact/block";
  static USER_EDIT_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/account/additionalusers/update";

  static ALL_NOTES_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation/note";
  static ADD_NOTES_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation/note";
  static DELETE_NOTES_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation/note/delete";
  static CHAT_LIST_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp/conversations";
  static CHAT_MESSAGES_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation/messages";
  static DIRECT_MESSAGES_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp/message/direct";
  static CONVERSATION_UPDATE_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation/agent";
  static UPDATE_AGENT_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/conversation";
  static CONVERSATION_TAGS_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/account/conversation/tag/fetch";
  static All_TAG_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/account/tag/fetch";
  static CREATE_TAG_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/account/tag/add";
  static ASSIGN_TAG_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/account/conversation/tag";
  static REMOVE_TAG_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/account/conversation/tag/delete";
  static DELETE_TAG_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/account/tag";
  static CHAT_LIST_BY_TAG_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp/conversations/tags/assigned";
  static CONFIGURE_WEBHOOK_BASE_URL: string =
    URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/webhook";
  static DOwNLOAD_ANALYTICS_BASE_URL: string =
    URLConstants.GAAS_BASE_URL + "/cloud/accounts/me/v2/messages/report";
  static DOwNLOAD_CHAT_BASE_URL: string =
    URLConstants.GAAS_BASE_URL + "/cloud/accounts/me/conversationmessages";
  static DOWNLOAD_CHATBOT_REPORT_BASE_URL: string =
    URLConstants.GAAS_BASE_URL + "/advanced-chatbot";
  static DOwNLOAD_CAMPAIGN_REPORT_BASE_URL: string =
    URLConstants.GAAS_BASE_URL + "/cloud/accounts/me/v2/campaigns/report";
  static DOwNLOAD_CONVERSATION_REPORT_BASE_URL: string =
    URLConstants.GAAS_BASE_URL + "/cloud/accounts/me/v2/conversation/report";
  static TEMPLATE_MASTER_DATA_BASE_URL: string =
    URLConstants.G_360_BASE_URL + "/adapter/d360/templates/configs";
  static CREATE_TEMPLATE_BASE_URL: string =
    URLConstants.G_360_BASE_URL + "/adapter/d360/template";
  static QUICK_REPLY_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp-business/accounts/quick-reply";
  static QUICK_REPLY_ENABLE_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp-business/accounts/quickreply";
  static API_KEY_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/users/me/api-key";
  static BLOCK_API_KEY_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/users/me/api-key/";
  static USER_DELETE_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/account/additionalusers";
  static USER_NAME_VERIFICATION_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/forgot/password/otp";
  static RESET_FORGOT_PASSWORD_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/reset/password/otp";
  static RESET_PASSWORD_BASE_URL: string =
    URLConstants.GAUS_BASE_URL + "/reset/password";

  /** RCS Url */
  static CREATE_RCS_ACCOUNT_BASE_URL: string =
    URLConstants.GARCS_BASE_URL + "/rcs/account";
  static UPDATE_RCS_ACCOUNT_BASE_URL: string =
    URLConstants.GARCS_BASE_URL + "/rcs/account";
  static GET_RCS_ACCOUNT_BASE_URL: string =
    URLConstants.GARCS_BASE_URL + "/rcs/account";

  /** Agent assign feature url */
  static AGENT_ASSIGN_FEATURE_URL: string =
    URLConstants.GAUS_BASE_URL + "/account/agent-assign";

  /** Get Account by id feature url */
  static AGENT_ASSIGN_FEATURE_BY_ID_URL: string =
    URLConstants.GAUS_BASE_URL + "/account/features";

  //GAMS URLs
  static SAVE_MEDIA_BASE_URL: string =
    URLConstants.GAMS_BASE_URL + "/adapter/files/save";

  //TODO
  static UPLOAD_FILE_BASE_URL: string =
    "https://fs.telinfy.net/adapter/files/upload";

  static SEND_MESSAGE_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp/templates/message";
  static SEND_CAMPAIGN_BASE_URL: string =
    URLConstants.GACA_BASE_URL + "/whatsapp/campaign";

  /** RCS campaign url's  */
  static SEND_RCS_CAMPAIGN_MESSAGE_BASE_URL: string =
    URLConstants.GARCS_BASE_URL + "/rcs/campaign"

  static RCS_CAMPAIGN_LIST_ALL: string =
    URLConstants.GARCS_BASE_URL + "/rcs/all/campaigns"
    
    static CREATE_CHATBOT_BASE_URL: string = URLConstants.GACA_BASE_URL + "/whatsapp-business/accounts/advanced-bot-config";
    static GET_CHATBOT_LIST_BASE_URL: string = URLConstants.GACA_BASE_URL + "/whatsapp-business/accounts/advanced-bot-configs";
    static ENABLE_DISABLE_CHATBOT_BASE_URL: string = URLConstants.GACA_BASE_URL + "/whatsapp-business/accounts/advanced-bot-config/state";
    static GET_DOWNLOAD_CENTER_REPORTS_BASE_URL: string = URLConstants.GAAS_BASE_URL + "/reports";
    static REPORT_TO_DOWNLOAD_CENTER: string = URLConstants.GAAS_BASE_URL + "/cloud/accounts/me/v2/messages/report";
    static SEND_CAMPAIGN_USING_JSON_BASE_URL: string = URLConstants.GACA_BASE_URL + "/v2/whatsapp/campaign";
  }

